.case-card {
  transition: all 0.8s;
  height: 20rem;
  color: white;
}
.case-card .case-txt-1 {
  position: relative;
  top: 5rem;
  transition: all 0.8s;
}
.case-card .case-txt-2 {
  opacity: 0;
  transition: all 0.8s;
}
.case-card .case-fundo {
  background-color: transparent;
  width: 100%;
  height: 100%;
  transition: all 0.8s;
}

.case-card:hover .case-txt-1 {
  top: -10rem;
}
.case-card:hover .case-txt-2 {
  opacity: 1;
}
.case-card:hover .case-fundo {
  background-color: #80008080;
}

#cards {
  background-color: #EFEFEF;
  font-family: Objective;
  padding-bottom: 6rem;
}

#cards .cont-top {
  position: relative;
  top: -3rem;
}

#cards .card1 {
  background-color: #277BC0;
  border-radius: 10px;
  transition: transform 0.8s;
  min-width: 300px;
  max-height: 240px;
}

#cards .card1:hover {
  background: url(/img/home/back1.png) center no-repeat;
  background-size: cover;
  transform: translate(0, -20px);
  max-width: 500px;
  box-shadow: 0px 8px 28px 0px #9e9e9e;
}
#cards .card1:hover .icone {
  opacity: 1;
}

#cards .card2 {
  background-color: #277BC0;
  border-radius: 10px;
  transition: transform 0.8s;
  min-width: 300px;
  max-height: 240px;
}

#cards .card2:hover {
  background: url(/img/home/back2.png) center no-repeat;
  background-size: cover;
  transform: translate(0, -20px);
  max-width: 500px;
  box-shadow: 0px 8px 28px 0px #9e9e9e;
}
#cards .card2:hover .icone2 {
  opacity: 1;
}

#cards .card3 {
  background-color: #277BC0;
  border-radius: 10px;
  transition: transform 0.8s;
  min-width: 300px;
  max-height: 240px;
}

#cards .card3:hover {
  background: url(/img/home/back3.png) center no-repeat;
  background-size: cover;
  transform: translate(0, -20px);
  max-width: 500px;
  box-shadow: 0px 8px 28px 0px #9e9e9e;
}
#cards .card3:hover .icone {
  opacity: 1;
}

#cards .card4 {
  background-color: #277BC0;
  border-radius: 10px;
  transition: transform 0.8s;
  min-width: 300px;
  max-height: 240px;
}

#cards .card4:hover {
  background: url(/img/home/back4.png) center no-repeat;
  background-size: cover;
  transform: translate(0, -20px);
  max-width: 500px;
  box-shadow: 0px 8px 28px 0px #9e9e9e;
}
#cards .card4:hover .icone {
  opacity: 1;
}

#cards h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #fff;
}

#cards .icone {
  background-color: rgba(255, 255, 255, 0.486);
  color: #fff;
  font-size: 40px;
  border-radius: 30px;
  position: absolute;
  bottom: 2rem;
  left: 15rem;
  opacity: 0;
}

#cards .icone:hover {
  background-color: #3156A3;
}

#cards .icone2 {
  background-color: rgba(255, 255, 255, 0.486);
  color: #fff;
  font-size: 40px;
  border-radius: 30px;
  position: absolute;
  bottom: 2rem;
  left: 15rem;
  opacity: 0;
}

#cards .icone2:hover {
  background-color: #3156A3;
}

#inicio {
  font-family: Objective;
}

#inicio h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 26.2857px;
  line-height: 28px;
  color: #000;
}

#inicio h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 76.9796px;
  line-height: 82px;
  color: #000;
}

#inicio p {
  font-family: Objective;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #666666;
}

#inicio img {
  position: relative;
  top: -6rem;
}

#inicio .btn-azul-claro {
  padding: 20px 60px;
  background-color: #46BED8;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
}

#inicio .btn-azul-claro:hover {
  background-color: #277BC0;
  box-shadow: 0px 8px 28px 0px #c4c4c4;
}

#inicio h4 {
  font-family: Objective;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  color: #000;
}

#cor-azul {
  background-color: #277BC0;
  min-height: 400px;
  font-family: Objective;
}

#cor-azul h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 49px;
  color: #fff;
}

#cor-azul span {
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 49px;
  color: #46BED8;
}

#carrossel {
  position: relative;
  top: -15rem;
  margin-bottom: -260px;
}

#carrossel .direita {
  max-width: 850px;
  border-radius: 0px 10px 10px 0px;
  background-color: #F6F6F6;
}

#carrossel .icone {
  background-color: rgba(255, 255, 255, 0.24);
  border-radius: 30px;
  font-size: 3rem;
  color: #fff;
}

.quadro1 {
  min-height: 370px;
}

.ite {
  filter: drop-shadow(4px 5px 5px #979797);
}

.owl-prev,
.owl-next {
  position: absolute;
  top: 40%;
  transform: translateY(-60%);
}

.owl-prev {
  left: -2rem;
}

.owl-next {
  right: -2rem;
}

#numeros h3 {
  font-family: Objective;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 39px;
  color: #000;
}

#numeros span {
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 39px;
  color: #46BED8;
}

#numeros img {
  min-width: 60px;
}

#numeros h4 {
  font-family: Objective;
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 86px;
  color: #277BC0;
}

#numeros p {
  font-family: Objective;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #000;
}

#final {
  font-family: Objective;
  background: url(/img/final/back.png) center no-repeat;
  background-size: cover;
  max-height: 450px;
  margin-bottom: 330px;
}

#final .texto {
  padding: 70px 0px;
}

#final p {
  font-weight: bold;
  font-size: 36px;
  line-height: 39px;
  color: #fff;
}

#final span {
  font-weight: bold;
  font-size: 36px;
  line-height: 39px;
  color: #46BED8;
}

#final .btn-branco {
  padding: 15px 50px;
  background-color: #fff;
  border-radius: 10px;
  color: #277BC0 !important;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
}

#final .btn-branco:hover {
  background-color: #46BED8;
  color: #fff !important;
}

#final .imagens {
  position: relative;
  top: -2rem;
}

#final .img1 {
  background: url(/img/final/img1.png) center no-repeat;
  background-size: contain;
  min-height: 440px;
}
#final .img1 .desc {
  opacity: 0;
}

#final .img1:hover {
  background: url(/img/final/bg1.png) center no-repeat;
  background-size: contain;
  min-height: 440px;
}
#final .img1:hover .desc {
  opacity: 1;
}

#final .img2 {
  background: url(/img/final/img2.png) center no-repeat;
  background-size: contain;
  min-height: 440px;
}
#final .img2 .desc {
  opacity: 0;
}

#final .img2:hover {
  background: url(/img/final/bg2.png) center no-repeat;
  background-size: contain;
  min-height: 440px;
}
#final .img2:hover .desc {
  opacity: 1;
}

#final .img3 {
  background: url(/img/final/img3.png) center no-repeat;
  background-size: contain;
  min-height: 440px;
}
#final .img3 .desc {
  opacity: 0;
}

#final .img3:hover {
  background: url(/img/final/bg3.png) center no-repeat;
  background-size: contain;
  min-height: 440px;
}
#final .img3:hover .desc {
  opacity: 1;
}

#final h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 21px;
  color: #fff;
  padding-top: 180px;
}

#final .lupa {
  background-color: rgba(255, 255, 255, 0.651);
  border-radius: 30px;
  padding: 10px;
  font-size: 25px;
  color: #fff;
}

#final .lupa:hover {
  background-color: #fff;
  color: #5E358C;
}

.modal-dialog {
  max-width: 1170px;
}

.modal-content {
  box-shadow: 0px 8px 28px 0px #5f5f5f;
}

.modal-header {
  border-bottom: 0px;
}
.modal-header h5 {
  font-family: Objective;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 25px;
  color: #277BC0;
}

.modal-body p {
  font-family: Objective;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #000;
}

#blog-meio {
  background-color: #EFEFEF;
  font-family: Objective;
  max-height: 500px;
}

#blog-meio .container {
  position: relative;
  top: -11rem;
}

#blog-meio .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: 10px;
}

#blog-meio .imagem-post {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 230px;
  border-radius: 10px 10px 0px 0px;
}

#blog-meio .icone {
  color: #9F9F9F;
  font-size: 25px;
}

#blog-meio .card:hover {
  box-shadow: 0px 8px 28px 0px #b0b0b0;
  transition-duration: 0.3s;
  transform: scale(1.1);
}

#blog-meio .btn-blog-mais {
  font-family: Objective;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #9F9F9F !important;
}

#blog-meio .icone-bt {
  font-size: 20px;
  color: #9F9F9F;
}

#blog-meio .btn-blog-mais:hover {
  border-bottom: 3px solid #277BC0;
  color: #000;
}
#blog-meio .btn-blog-mais:hover .icone-bt {
  color: #277BC0;
}

#blog-meio .quadro2 {
  min-height: 500px;
}

#blog-meio .b-texto {
  position: relative;
  left: -30px;
}

#blog-meio h4 {
  font-family: Objective;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 25px;
}

#blog-meio span {
  font-family: Objective;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 25px;
  color: #46BED8;
}

#blog-meio .card h5 {
  font-family: Objective;
  font-weight: 900;
  font-size: 20px;
  line-height: 20px;
  color: #000;
}

#blog-meio .datas {
  font-family: Objective;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
}

#final2 {
  background: url(/img/img-final.png) center no-repeat;
  background-size: cover;
  padding: 160px 0px;
  font-family: Objective;
}

#final2 .container {
  position: relative;
  top: -3rem;
}

#final2 h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 39px;
  color: #000;
}

#final2 span {
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 39px;
  color: #46BED8;
}

#final2 p {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #666666;
}

#final2 .btn-azul {
  padding: 15px 60px;
  background-color: #277BC0;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
}

#final2 .btn-azul:hover {
  background-color: #3156A3;
  box-shadow: 0px 8px 28px 0px #c4c4c4;
}

@media (max-width: 1399.98px) {
  #cards {
    padding-bottom: 3rem;
  }

  #cards .card1 {
    min-width: 280px;
    max-height: 240px;
  }

  #cards .card2 {
    min-width: 280px;
    max-height: 240px;
  }

  #cards .card3 {
    min-width: 280px;
    max-height: 240px;
  }

  #cards .card4 {
    min-width: 280px;
    max-height: 240px;
  }

  #cards .icone {
    bottom: 2rem;
    left: 14rem;
  }

  .cor-azul {
    padding: 160px 0px;
  }

  #carrossel {
    top: -17rem;
    margin-bottom: -300px;
  }

  .owl-prev {
    left: 5rem;
  }

  .owl-next {
    right: 5rem;
  }

  #numeros h3 {
    font-size: 31px;
  }

  #numeros h4 {
    font-size: 40px;
  }

  #final2 {
    padding: 110px 0px;
  }
}
@media (max-width: 1200.98px) {
  #cards .card1 {
    min-width: 240px;
    max-height: 260px;
  }

  #cards .card2 {
    min-width: 240px;
    max-height: 260px;
  }

  #cards .card3 {
    min-width: 240px;
    max-height: 260px;
  }

  #cards .card4 {
    min-width: 240px;
    max-height: 260px;
  }

  #cards .icone {
    bottom: 2rem;
    left: 14rem;
    position: initial;
  }

  #cards .icone2 {
    bottom: 2rem;
    left: 14rem;
    position: initial;
  }

  #inicio .texto p {
    font-size: 16px;
  }

  #inicio h4 {
    position: relative;
    top: 4rem;
  }

  #numeros h3 {
    font-size: 28px;
  }

  #numeros p {
    font-size: 16px;
  }

  #final p {
    font-size: 32px;
  }

  #final .btn-branco {
    padding: 15px 20px;
  }
}
@media (max-width: 1050.98px) {
  #cards .card1 {
    min-width: 200px;
    max-height: 290px;
  }

  #cards .card2 {
    min-width: 200px;
    max-height: 290px;
  }
  #cards .card2 h2 {
    word-break: break-word;
  }

  #cards .card3 {
    min-width: 200px;
    max-height: 290px;
  }

  #cards .card4 {
    min-width: 200px;
    max-height: 290px;
  }
}
@media (max-width: 991.98px) {
  #cards .card1 {
    min-width: 200px;
    max-height: 290px;
    min-height: 280px;
  }

  #cards .card2 {
    min-width: 200px;
    max-height: 290px;
    min-height: 280px;
  }

  #cards .card3 {
    min-width: 200px;
    max-height: 290px;
    min-height: 280px;
  }

  #cards .card4 {
    min-width: 200px;
    max-height: 290px;
    min-height: 280px;
  }

  #inicio h4 {
    position: relative;
    top: 6rem;
  }

  .cor-azul {
    padding: 130px 0px;
  }

  .cor-azul .texto {
    padding-right: 0px;
  }

  #carrossel {
    top: -15rem;
    margin-bottom: -230px;
  }

  .owl-prev {
    left: -4rem;
  }

  .owl-next {
    right: -4rem;
  }

  .owl-prev,
.owl-next {
    position: absolute;
    top: 34%;
    transform: translateY(-60%);
  }

  #carrossel .icone {
    width: 0px;
  }

  #final .img1 {
    min-height: 350px;
  }

  #final .img1:hover {
    min-height: 350px;
  }

  #final .img2 {
    min-height: 350px;
  }

  #final .img2:hover {
    min-height: 350px;
  }

  #final .img3 {
    min-height: 350px;
  }

  #final .img3:hover {
    min-height: 350px;
  }

  #final h4 {
    padding-top: 130px;
  }

  #final {
    margin-bottom: 1050px;
  }

  .modal-dialog {
    max-width: 910px;
  }

  #final2 {
    margin-top: 70px;
    padding: 20px 0px;
  }
  #final2 h2 {
    color: #fff;
  }
  #final2 p {
    color: #fff;
  }

  #final2 .container {
    background-color: #277bc075;
    border-radius: 20px;
    padding: 50px 0px;
  }
}
@media (max-width: 767.98px) {
  #cards .card1 {
    min-width: 200px;
    max-height: 290px;
    min-height: 240px;
  }

  #cards .card2 {
    min-width: 200px;
    max-height: 290px;
    min-height: 240px;
  }

  #cards .card3 {
    min-width: 200px;
    max-height: 290px;
    min-height: 240px;
  }

  #cards .card4 {
    min-width: 200px;
    max-height: 290px;
    min-height: 240px;
  }

  #cor-azul h3 {
    font-size: 33px;
  }

  #meio img {
    max-width: 100px;
  }

  #carrossel img {
    border-radius: 10px 10px 0px 0px;
  }

  #carrossel .direita {
    min-height: 270px;
    max-width: 850px;
    border-radius: 0px 0px 10px 10px;
    background-color: #f6f6f6;
  }

  #carrossel .icone {
    width: 0px;
  }

  .owl-stage {
    min-height: 620px;
  }

  .owl-item {
    filter: drop-shadow(0px 8px 9px #979797);
  }

  #final {
    max-height: 360px;
    margin-bottom: 1150px;
  }

  .modal-dialog {
    max-width: 650px;
  }

  #final2 {
    margin-top: 70px;
    padding: 0px 0px;
  }
}
@media (max-width: 575.98px) {
  #cards .card1 {
    min-width: 200px;
    max-height: 290px;
    min-height: 270px;
  }

  #cards .card2 {
    min-width: 200px;
    max-height: 290px;
    min-height: 270px;
  }

  #cards .card3 {
    min-width: 200px;
    max-height: 290px;
    min-height: 270px;
  }

  #cards .card4 {
    min-width: 200px;
    max-height: 290px;
    min-height: 270px;
  }

  #inicio h1 {
    font-size: 62px;
  }

  .cor-azul {
    background-size: inherit;
  }

  .cor-azul .texto {
    padding-right: 0px;
  }
}
@media (max-width: 530.98px) {
  #carrossel .direita {
    min-height: 290px;
    max-width: 850px;
    border-radius: 0px 0px 10px 10px;
  }

  .owl-stage {
    min-height: 700px;
  }
}
@media (max-width: 480.98px) {
  #carrossel .direita {
    min-height: 350px;
    max-width: 850px;
    border-radius: 0px 0px 10px 10px;
  }
}
@media (max-width: 430.98px) {
  #inicio h1 {
    font-size: 55px;
  }

  #final h4 {
    padding-top: 100px;
  }

  #final .img1 {
    min-height: 300px;
    background-size: cover;
  }

  #final .img1:hover {
    min-height: 300px;
    background-size: cover;
  }

  #final .img2 {
    min-height: 300px;
    background-size: cover;
  }

  #final .img2:hover {
    min-height: 300px;
    background-size: cover;
  }

  #final .img3 {
    min-height: 300px;
    background-size: cover;
  }

  #final .img3:hover {
    min-height: 300px;
    background-size: cover;
  }

  #final {
    margin-bottom: 1000px;
  }
}
@media (max-width: 400.98px) {
  #carrossel .direita {
    min-height: 400px;
    max-width: 850px;
    border-radius: 0px 0px 10px 10px;
  }

  .owl-stage {
    min-height: 680px;
  }

  #final h4 {
    padding-top: 80px;
  }
}
@media (max-width: 370.98px) {
  #carrossel .direita {
    min-height: 450px;
    max-width: 850px;
    border-radius: 0px 0px 10px 10px;
  }

  .owl-stage {
    min-height: 680px;
  }

  #final h4 {
    padding-top: 80px;
  }
}